import { graphql } from "gatsby"
import React from "react"

import CareerContact from "../../../components/career/career-contact/career-contact"
import InitiativeApplication from "../../../components/career/initiative-application/initiative-application"
import StudiesAndTrainingOverview from "../../../components/career/studies-and-trining-overview/studies-and-training-overview"
import TextWithOrWithoutGallery from "../../../components/dynamic-page-components/text-with-or-without-gallery/text-with-or-without-gallery"
import { GalleryPictureItem } from "../../../components/gallery/model/gallery-item"
import OverviewGrid from "../../../components/overview-grid/overview-grid"
import SEO from "../../../components/seo"
import Tile from "../../../components/tile/tile"
import TileContentSpacer from "../../../components/tile/tile-content-spacer/tile-content-spacer"
import SideNavView from "../../../components/views/side-nav-view/side-nav-view"
import useCareerContacts from "../../../hooks/useCareerContacts"
import useNav from "../../../hooks/useNav"
import { useTranslation } from "../../../i18n/translate"
import { CareerContactModel } from "../../../model/careerContactModel"
import { dynamicImageWithGatsbyImage } from "../../../util/dynamic-image"

const StudiesAndTrainingPageHoc = ({ location, data }) => {
  const contacts = CareerContactModel.getCareerContactsByNames(
    useCareerContacts(),
    [
      ["Alexandra", "Brehm"],
      ["Kathrin", "Zimmermann"],
    ],
  )
  const { t } = useTranslation()
  const openVacanciesNav = useNav({ uid: "open-vacancies" })
  const recruitingNav = useNav({ uid: "recruiting-process" })
  return (
    <StudiesAndTrainingPage
      t={t}
      location={location}
      contacts={contacts}
      openVacanciesNav={openVacanciesNav}
      recruitingNav={recruitingNav}
      data={data}
    />
  )
}

class StudiesAndTrainingPage extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { t, contacts, location, openVacanciesNav, recruitingNav, data } =
      this.props

    return (
      <>
        <SEO
          pathName={location.pathname}
          titleTranslatedString={t("CAREER-STUDIES_AND_TRAINING-META_TITLE")}
          descriptionTranslatedString={t(
            "CAREER-STUDIES_AND_TRAINING-META_DESCRIPTION",
          )}
        />
        <SideNavView
          titleTranslatedString={t(
            "MENU-CAREER-STUDIES_AND_TRAINING_PAGE_TITLE",
          )}
          pathName={location.pathname}
        >
          <div className="_fp-grid _fp-grid--gap">
            <div className="_fp-col-12">
              <Tile>
                <h2 className={"_fp-heading-1"}>
                  {t("CAREER-STUDIES_AND_TRAINING-TITLE")}
                </h2>
                <TileContentSpacer size={"extra-small"} />
                <h2 className={"_fp-heading-4"}>
                  {t("CAREER-STUDIES_AND_TRAINING-SUBTITLE")}
                </h2>
                <TileContentSpacer size={"extra-small"} />
                <TextWithOrWithoutGallery
                  centerText={true}
                  gallerySize={"large"}
                  gallery={[
                    new GalleryPictureItem({
                      gatsbyFluidImage: dynamicImageWithGatsbyImage(
                        data.studiesAndTrainingImage,
                      ),
                    }),
                  ]}
                  textTranslationKey={"CAREER-STUDIES_AND_TRAINING-TEXT"}
                ></TextWithOrWithoutGallery>
                <TileContentSpacer />
                <TextWithOrWithoutGallery
                  centerText={true}
                  gallerySize={"large"}
                  imageSide={"right"}
                  gallery={[
                    new GalleryPictureItem({
                      gatsbyFluidImage: dynamicImageWithGatsbyImage(
                        data.studiesAndTrainingImage2,
                      ),
                    }),
                  ]}
                  textTranslationKey={"CAREER-STUDIES_AND_TRAINING-TEXT_2"}
                ></TextWithOrWithoutGallery>
                <TileContentSpacer />
              </Tile>
            </div>
            <div className="_fp-col-12">
              <StudiesAndTrainingOverview></StudiesAndTrainingOverview>
            </div>
            <div className="_fp-col-12">
              <Tile titleTranslatedString={t("CAREER-CAREER_CONTACT")} noTile>
                <OverviewGrid size={"small"}>
                  {contacts.map((contact, index) => {
                    return (
                      <CareerContact
                        key={`recruiting-process-career-contact_${index}`}
                        contact={contact}
                      />
                    )
                  })}
                </OverviewGrid>
              </Tile>
            </div>
          </div>
        </SideNavView>
      </>
    )
  }
}

export default StudiesAndTrainingPageHoc

export const query = graphql`
  {
    studiesAndTrainingImage: strapiDynamicImage(
      uid: { eq: "STUDIES_AND_TRAINING" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
    studiesAndTrainingImage2: strapiDynamicImage(
      uid: { eq: "STUDIES_AND_TRAINING_2" }
    ) {
      ...DynamicImageWithGatsbyImage
    }
  }
`
